var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('packageConfig.agencyPackageOrderList')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('BRow', {
    staticClass: "mb-50"
  }, [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "order-code-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.orderCode')
    }
  }, [_c('BFormInput', {
    attrs: {
      "id": "order-code-filter",
      "debounce": "300",
      "placeholder": _vm.$t('packageConfig.columns.orderCode')
    },
    model: {
      value: _vm.orderCodeFilter,
      callback: function callback($$v) {
        _vm.orderCodeFilter = $$v;
      },
      expression: "orderCodeFilter"
    }
  })], 1)], 1), !_vm.isRoleF2 ? _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "agency-code-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.agency')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "agency-code-filter",
      "options": _vm.agencyOptions,
      "label": "agencyCode",
      "clearable": "",
      "loading": _vm.loadingAgencies,
      "placeholder": _vm.$t('packageConfig.placeholder.agency'),
      "reduce": function reduce(val) {
        return val.agencyCode;
      }
    },
    on: {
      "open": _vm.handleOpenAgency,
      "search": _vm.handleSearchAgency
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var agencyName = _ref.agencyName,
          agencyCode = _ref.agencyCode;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate"
        }, [_vm._v(" " + _vm._s(agencyCode) + " "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var agencyName = _ref2.agencyName,
          agencyCode = _ref2.agencyCode;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate"
        }, [_vm._v(" " + _vm._s(agencyCode) + " "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref3) {
        var loading = _ref3.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }], null, false, 3656176114),
    model: {
      value: _vm.agencyCodeFilter,
      callback: function callback($$v) {
        _vm.agencyCodeFilter = $$v;
      },
      expression: "agencyCodeFilter"
    }
  })], 1)], 1) : _vm._e(), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "package-config-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.packageConfig')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "package-config-filter",
      "options": _vm.packageConfigOptions,
      "label": "name",
      "clearable": "",
      "loading": _vm.loadingPackageConfigs,
      "placeholder": _vm.$t('packageConfig.columns.packageConfig'),
      "reduce": function reduce(val) {
        return val.id;
      }
    },
    on: {
      "open": _vm.handleOpenPackageConfig,
      "search": _vm.handleSearchPackageConfig
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref4) {
        var name = _ref4.name,
          price = _ref4.price;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(name) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.formatCurrency(price)) + ")")])])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref5) {
        var name = _ref5.name,
          price = _ref5.price;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(name) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.formatCurrency(price)) + ")")])])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref6) {
        var loading = _ref6.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.packageConfigIdFilter,
      callback: function callback($$v) {
        _vm.packageConfigIdFilter = $$v;
      },
      expression: "packageConfigIdFilter"
    }
  })], 1)], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "order-status-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.enable')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "order-status-filter",
      "options": _vm.orderStatusOptions,
      "label": "name",
      "clearable": "",
      "placeholder": _vm.$t('packageConfig.columns.enable'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref7) {
        var name = _ref7.name;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(name))) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref8) {
        var name = _ref8.name;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(name))) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref9) {
        var loading = _ref9.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.orderStatusFilter,
      callback: function callback($$v) {
        _vm.orderStatusFilter = $$v;
      },
      expression: "orderStatusFilter"
    }
  })], 1)], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "payment-method-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.paymentMethod')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "payment-method-filter",
      "options": _vm.paymentMethodOptions,
      "label": "name",
      "clearable": "",
      "placeholder": _vm.$t('packageConfig.columns.paymentMethod'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref10) {
        var name = _ref10.name;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(name))) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref11) {
        var name = _ref11.name;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(name))) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref12) {
        var loading = _ref12.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.paymentMethodFilter,
      callback: function callback($$v) {
        _vm.paymentMethodFilter = $$v;
      },
      expression: "paymentMethodFilter"
    }
  })], 1)], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-end h-100"
  }, [_c('BButton', {
    staticClass: "p-1",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v(" Xoá bộ lọc ")])], 1)])], 1), _c('BTable', {
    ref: "refTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 400px)",
      "height": "calc(100vh - 400px)"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.resultArray,
      "responsive": "",
      "striped": true,
      "fields": _vm.tableColumnsRole,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "busy": _vm.loading,
      "hover": true,
      "borderless": true
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.tableColumnsRole, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.columns.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(orderCode)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.orderCode) + " ")])];
      }
    }, {
      key: "cell(packageConfig)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', [_vm._v(_vm._s(item.packageConfig.name) + " x" + _vm._s(item.monthsUse) + " tháng")]), _c('em', [_vm._v("(" + _vm._s(_vm.formatCurrency(item.packagePrice)) + " "), _c('small', [_vm._v("VND")]), _vm._v(")")])];
      }
    }, {
      key: "cell(buyer)",
      fn: function fn(_ref15) {
        var _item$buyer, _item$buyer2;
        var item = _ref15.item;
        return [_c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s((_item$buyer = item.buyer) === null || _item$buyer === void 0 ? void 0 : _item$buyer.agencyCode) + " ")]), _c('small', {
          staticClass: "font-italic"
        }, [_vm._v("(" + _vm._s((_item$buyer2 = item.buyer) === null || _item$buyer2 === void 0 ? void 0 : _item$buyer2.agencyName) + ")")])])];
      }
    }, {
      key: "cell(agencyRetail)",
      fn: function fn(_ref16) {
        var _item$agencyRetail, _item$agencyRetail2, _item$agencyRetail3;
        var item = _ref16.item;
        return [_c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.agencyRetail ? item === null || item === void 0 ? void 0 : (_item$agencyRetail = item.agencyRetail) === null || _item$agencyRetail === void 0 ? void 0 : _item$agencyRetail.agencyCode : 'Áp dụng cho đại lý mới') + " ")]), (_item$agencyRetail2 = item.agencyRetail) !== null && _item$agencyRetail2 !== void 0 && _item$agencyRetail2.agencyName ? _c('small', {
          staticClass: "font-italic"
        }, [_vm._v("(" + _vm._s((_item$agencyRetail3 = item.agencyRetail) === null || _item$agencyRetail3 === void 0 ? void 0 : _item$agencyRetail3.agencyName) + ")")]) : _vm._e()])];
      }
    }, {
      key: "cell(payment)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', [item.totalDiscount ? _c('del', {
          staticClass: "font-small-4 font-italic text-danger"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice)) + " "), _c('small', [_vm._v("VND")])]) : _vm._e(), _c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice - ((item === null || item === void 0 ? void 0 : item.totalDiscount) || 0))) + " "), _c('small', [_vm._v("VND")])]), _c('BBadge', {
          attrs: {
            "pill": "",
            "variant": item.orderStatus === 'DONE' ? 'light-success' : item.orderStatus === 'PENDING' ? 'light-warning' : 'light-danger'
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(item.orderStatus))) + " ")]), _c('div', [_c('em', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(item.paymentMethod))) + " ")])])], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "d-flex-between gap-2"
        }, [_c('div', {
          staticClass: "d-flex gap-2"
        }, [_c('BButton', {
          attrs: {
            "variant": "flat-primary",
            "size": "sm"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" Chi tiết ")]), row.item.orderStatus === 'PENDING' ? _c('BButton', {
          attrs: {
            "variant": "success",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.paymentOrderHandle(row.item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('packageConfig.columns.payment')) + " ")]) : _vm._e()], 1), row.item.orderStatus === 'PENDING' ? _c('BButton', {
          attrs: {
            "variant": "flat-danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.cancelOrderHandle(row.item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('packageConfig.cancel')) + " ")]) : _vm._e()], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref18) {
        var _ref18$item = _ref18.item,
          packageConfig = _ref18$item.packageConfig,
          totalPrice = _ref18$item.totalPrice,
          totalDiscount = _ref18$item.totalDiscount,
          monthsExtra = _ref18$item.monthsExtra,
          totalAmountToBePaid = _ref18$item.totalAmountToBePaid,
          paymentMethod = _ref18$item.paymentMethod,
          createdAt = _ref18$item.createdAt,
          updatedAt = _ref18$item.updatedAt,
          packageCoupon = _ref18$item.packageCoupon;
        return [_c('div', {
          staticClass: "d-flex gap-3 align-items-stretch"
        }, [_c('PackageDetail', {
          attrs: {
            "options": packageConfig.descriptions
          }
        }), _c('b-card', {
          staticClass: "border mb-0",
          staticStyle: {
            "width": "30%"
          },
          attrs: {
            "body-class": "py-1 px-2"
          }
        }, [_c('h4', {
          staticClass: "font-weight-bolder text-dark"
        }, [_vm._v(" Thông tin thanh toán ")]), _vm._l({
          totalPrice: totalPrice,
          totalDiscount: totalDiscount,
          monthsExtra: monthsExtra,
          totalAmountToBePaid: totalAmountToBePaid,
          paymentMethod: paymentMethod,
          createdAt: createdAt,
          updatedAt: updatedAt
        }, function (value, key) {
          return _c('div', {
            key: key,
            staticClass: "my-50"
          }, [_c('div', {
            staticClass: "d-flex-between font-weight-bolder"
          }, [_c('div', [_c('div', {
            staticClass: "d-flex gap-1"
          }, [_c('div', [_vm._v(_vm._s(_vm.$t("packageConfig.columns.".concat(key))) + ": ")]), key === 'totalDiscount' && packageCoupon !== null && packageCoupon !== void 0 && packageCoupon.code ? _c('div', [_c('kbd', {
            staticClass: "text-dark"
          }, [_vm._v(_vm._s(packageCoupon === null || packageCoupon === void 0 ? void 0 : packageCoupon.code))])]) : _vm._e()]), key === 'totalDiscount' && packageCoupon !== null && packageCoupon !== void 0 && packageCoupon.code ? _c('div', [packageCoupon !== null && packageCoupon !== void 0 && packageCoupon.percentage ? _c('small', {
            staticClass: "font-italic"
          }, [_vm._v(" Giảm giá " + _vm._s(packageCoupon.percentage) + "% "), packageCoupon.amountMax ? _c('span', [_vm._v("(tối đa " + _vm._s(_vm.formatCurrency(packageCoupon.amountMax)) + " VND)")]) : _vm._e()]) : packageCoupon !== null && packageCoupon !== void 0 && packageCoupon.amountMax ? _c('small', {
            staticClass: "font-italic"
          }, [_vm._v(" Giảm giá " + _vm._s(_vm.formatCurrency(packageCoupon.amountMax)) + " VND ")]) : _vm._e()]) : _vm._e()]), key === 'paymentMethod' ? _c('div', [_c('span', {}, [_vm._v(_vm._s(_vm.$t("packageConfig.".concat(value))))])]) : ['createdAt', 'updatedAt'].includes(key) ? _c('div', [_c('span', {}, [_vm._v(_vm._s(_vm.convertISODateTime(value).dateTime))])]) : ['totalPrice', 'totalAmountToBePaid', 'totalDiscount'].includes(key) ? _c('div', [_c('span', {}, [_vm._v(_vm._s(_vm.formatCurrency(value)))])]) : _c('div', [_c('span', {}, [_vm._v(_vm._s(value))])])])]);
        })], 2)], 1)];
      }
    }], null, true)
  }), _c('b-row', {
    staticClass: " d-flex-center justify-content-md-between"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-50 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " ")]), _c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false,
      "append-to-body": true,
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.from')) + " " + _vm._s(_vm.fromItem) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.toItem) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.totalItems) + " ")])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-25 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('ModalBuy', {
    attrs: {
      "buy-item-props": _vm.paymentItem,
      "agency-item": _vm.agencyItem,
      "qr-code": _vm.qrCode,
      "payment-method-prop": _vm.paymentMethod,
      "months-use-prop": _vm.monthsUse,
      "is-payment": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }